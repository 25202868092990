import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';

const exampledata = [
];

const isImageUrl = (url) => {
  return /\.(jpeg|jpg|gif|png)$/.test(url);
};

const ScreenshotComponent = () => {
  const [data, setData] = useState(exampledata);
  const chatRef = useRef(null);

  const changeData = (value) => {
    try {
      setData(JSON.parse(value))
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  };

  const takeScreenshot = () => {
    html2canvas(chatRef.current, {
      useCORS: true,
      allowTaint: true,
      backgroundColor: null,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'screenshot.png';
      link.click();
    }).catch((err) => {
      console.error("Error capturing screenshot: ", err);
    });
  };

  const addUserMessage = () => {
    setData([...data, { role: 'user', content: 'This is a default user message.' }]);
  };

  const addAIMessage = () => {
    setData([...data, { role: 'ai', content: 'This is a default AI message.' }]);
  };

  const addImage = () => {
    setData([...data, { role: 'system', content: 'https://example.com/default-image.png' }]);
  };
  const addBlurredImage = () => {
    setData([...data, { role: 'blurred', content: 'https://example.com/default-image.png' }]);
  };

  const addAudio = () => {
    setData([...data, { role: 'audio', content: 'https://example.com/default-audio.mp3' }]);
  };

  return (
    <div style={{ padding: '10px', display: "flex", gap: "30px" }}>
      <div ref={chatRef} style={{ width: "500px" }}>
        <img src="iphonebar.png" width="100%" style={{ marginBottom: "-5px" }} />
        <img src="videomakerheader.png" width="100%" style={{ marginBottom: "-4px", borderBottom: "1px solid #adb5bd" }} />
        <div style={{ display: 'flex', flexDirection: 'column', background: "#f8f9fa", height: "auto", paddingBottom: "20px", padding: "10px" }}>
          {data && data.length > 0 ? (
            data.map((msg, index) => (
              <div
                key={index}
                style={{
                  alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start',
                  backgroundColor:
                    msg.role === 'user'
                      ? '#2A8BD4'
                      : isImageUrl(msg.content) || msg.role === 'audio'
                        ? 'transparent'
                        : '#EFEFEF',
                  color: msg.role === 'user' ? 'white' : 'black',
                  borderRadius: '20px',
                  padding: '0px 20px',
                  margin: '5px 0',
                  maxWidth: '60%',
                  wordWrap: 'break-word',
                }}
              >
                {msg.role=="system" && isImageUrl(msg.content) ? (
                  <div style={{ display: 'flex', justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start' }}>
                    <img
                      src={msg.content}
                      alt="message content"
                      style={{ width: '250px', height: '250px', borderRadius: '20px' }}
                    />
                  </div>
                ) : 
                msg.role === 'blurred' ? (
                    <div style={{ display: 'flex', justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start', overflow:"hidden", borderRadius:"20px"}}>
                        <img
                        src={msg.content}
                        alt="message content"
                        style={{ width: '250px', height: '250px', borderRadius: '20px', filter: 'blur(15px)' }}
                        />
                    </div>
                    ) :

                msg.role === 'audio' ? (
                  <div style={{ display: 'flex', justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start' }}>
                    <audio
                      src={msg.content}
                      controls
                      controlsList="nodownload noplaybackrate"
                    />
                  </div>
                ) : (
                  <p>
                    {msg.content.split(/(\*\*[^*]+\*\*)/).map((part, idx) =>
                      part.startsWith('**') && part.endsWith('**') ? (
                        <strong key={idx}>{part.slice(2, -2)}</strong>
                      ) : (
                        part
                      )
                    )}
                  </p>
                )}
              </div>
            ))
          ) : null}
        </div>
      </div>

      <textarea style={{ width: "700px", height: "500px", marginTop: "20px" }} value={JSON.stringify(data, undefined, 4)} onChange={(e) => changeData(e.target.value)}></textarea>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <button onClick={addUserMessage}>Add User Message</button>
        <button onClick={addAIMessage}>Add AI Message</button>
        <button onClick={addImage}>Add Image</button>
        <button onClick={addBlurredImage}>Add Blurred Image</button>
        <button onClick={addAudio}>Add Audio</button>
      </div>
    </div>
  );
};

export default ScreenshotComponent;

import React, { useState, useEffect } from 'react';
import "./Chat.css";

const Loader = () => <div className="imageLoader"><div className="lds-heart"><div></div></div></div>;

const ImageWithLoader = ({ src, openImageModal, paid, index, sweety, openPaymentModal }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
    img.onerror = () => {
      console.log("Error loading image");
      // Handle image load error if needed
    };
  }, [src]);

  return (
    <>
      {!loaded && <Loader />}
      <div
        onClick={() => {
          if (index == 0) {
            openImageModal(src);
          } else {
            if (paid) {
              openImageModal(src);
            } else {
              openPaymentModal();
              sweety("Upgrade to any plan to see photos of her.");
            }
          }
        }}
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: index == 0 ? 'none' : paid ? 'none' : 'blur(7px)',
          width: '250px',
          height: '250px',
          borderRadius: '20px',
          cursor: 'pointer',
          display: loaded ? 'block' : 'none'
        }}
        className="ChatImageSmall"
      ></div>
    </>
  );
};

export default ImageWithLoader;

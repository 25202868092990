import React, { useState, useEffect } from 'react';
import './App.css';

const GalleryModal = ({ onClose, email }) => {
    const [imageUrls, setImageUrls] = useState([]);
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);

    useEffect(() => {
      fetch(`https://lollipopchat-ca692e070ff0.herokuapp.com/get-image-urls?email=${email}`)
        .then(response => response.json())
        .then(data => setImageUrls(data.imageUrls))
        .catch(error => console.error('Error fetching image URLs:', error));
    }, [email]);

    const openFullscreen = (url) => {
      setSelectedImageUrl(url);
    };

    const closeFullscreen = () => {
      setSelectedImageUrl(null);
    };

    return (
      <>
      <div className="closeGallery" onClick={onClose}>Close gallery</div>
        <div className="backdrop" onClick={onClose}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <div className="gallery-container">
              {imageUrls.map((url, index) => (
                <img key={index} src={url} alt={`Generated ${index}`} className="gallery-image" onClick={() => openFullscreen(url)} />
              ))}
            </div>
          </div>
        </div>
        {selectedImageUrl && (
          <div className="fullscreen-backdrop" onClick={closeFullscreen}>
            <img src={selectedImageUrl} alt="Fullscreen" className="fullscreen-image" />
          </div>
        )}
      </>
    );
};

export default GalleryModal;

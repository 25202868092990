import React, { useState, useEffect } from 'react';
import './App.css';
import OpenAI from 'openai';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from './firebase'; // Import auth
import { signOut } from "firebase/auth";
import GalleryModal from './GalleryModal';
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";

const TRACKING_ID = "G-945Q55G4PY"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [imageUrl, setImageUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);

  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState(null);

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const openGallery = () => setIsGalleryOpen(true);
  const closeGallery = () => setIsGalleryOpen(false);

  const auth = getAuth();
  const provider = new GoogleAuthProvider();


  useEffect(() => {
    ReactGA.event({
      category: 'User',
      action: 'Came to site'
    });
    if(!user && localStorage.getItem('tos') !== "true"){
      setIsModal2Open(true)
    }
  }, []);

  

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setUser(user);
    });
  }, []);

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const signOutUser = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const saveImageUrlToLocalStorage = () => {
    // Define an array of default image URLs
    const defaultImages = ["1.png", "2.png", "3.png"]
  
    // Attempt to retrieve existing images from localStorage
    const savedImages = JSON.parse(localStorage.getItem('generatedImages')) || [];
  
    // Check if there are any images in localStorage
    if (savedImages.length === 0) {
      // If not, initialize localStorage with default images
      localStorage.setItem('generatedImages', JSON.stringify(defaultImages));
    }
  };
  

  const generateImage = async () => {
    if (!user) {
      console.log("User not signed in.")
      signInWithGoogle();
      return;
    }
    console.log("Generating image.")
    setLoading(true);
    setTimeout(() => {
      openImageModal()
      setLoading(false);
      saveImageUrlToLocalStorage()
    }, 2000);
};

const acceptTerms = () => {
  localStorage.setItem('tos', "true");
  setIsModal2Open(false);
}

const closeModal2 = () => {
  setIsModal2Open(false);
};
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const Modal = ({ url, onClose }) => {
    return (
      <div className="backdrop" onClick={onClose}>
        <div className="modal" onClick={e => e.stopPropagation()}>
          <div className="image-container">
            <img className="theporn" src={url} alt="Generated" />
            <a href={url} download="image.png" className="download-button">
              <i className="download-icon"></i> {/* Icon placeholder */}
            </a>
          </div>
        </div>
      </div>
    );
  };

  const Modal2 = ({ url, onClose }) => {
    return (
      <div className="backdrop backdrop2" >
        <div className="modal" onClick={e => e.stopPropagation()}>
          <div className="termsmodal">
            I am 18 years of age or older, and <Link to="tos" style={{textDecoration:"none"}}>I accept the terms and conditions.</Link>
            <div className="acceptterms" onClick={() => acceptTerms()}>I accept</div>
            </div>
        </div>
      </div>
    );
  };

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const ImageModal = ({ onClose }) => {
    return (
      <div className="image-modal-backdrop" onClick={onClose}>
        <div className="image-modal-content" onClick={e => e.stopPropagation()}>
          The beta is currently full. <br/> You'll get access as soon as possible. <br/> <span style={{fontSize:"16px"}}>(PS. Check your gallery! ❤️)</span>
        </div>
      </div>
    );
  };

  const openImageModal = () => {
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };
  
  
  

  return (
    <div className="App">
      <div className="nav">
        <div className="brand">Lolli<span style={{color:"#F339CA"}}>pop</span></div>
        <div className="links">
        { user ? <div className="link" onClick={openGallery}>My gallery</div> : null}
        {user ? (
          <>
            <div className="link" onClick={signOutUser}>{user.displayName}</div>
          </>
        ) : (
          <div className="link" onClick={signInWithGoogle}>Login</div>
        )}

      </div>
    </div>
    <div className="rightSide">
    {/*<div className="profile">
      <div style={{fontSize:"24px", fontWeight:"600"}}>Elsa Brigitta, 24</div>
      <div> <img src="https://static-00.iconduck.com/assets.00/location-indicator-red-emoji-1330x2048-pfre7pru.png" width="10px"/> Stockholm, Sweden</div>
      <div style={{fontSize:"14px", marginTop:"10px", textAlign:"left"}}>I like hiking, volleyball, and most of all staying in bed watching movies. First year in adult entertainment.</div>
      <a href="https://www.instagram.com/elsabrigittaswe/" target="_blank" style={{marginTop:"15px", display:"flex", alignItems:"center", gap:"10px", fontSize:"13px", textDecoration:"none", color:"white"}} className="igbutton"><img src="ig.png" width="18px" /> @elsabrigittaswe</a>
    </div>
    */}

      <div className="query">
        <input className="searchbar" type="text" placeholder="Imagine your dream girl..." />
        <button type="button" disabled={loading} onClick={generateImage}>{loading ? <img src="./loader.svg" width="24px"/> : "Make her"}</button>
      </div>
      </div>
      {isModal2Open && <Modal2 onClose={closeModal2} />}
      {isModalOpen && <Modal url={imageUrl} onClose={closeModal} />}
      {isGalleryOpen && <GalleryModal onClose={closeGallery} />}
      {isImageModalOpen && <ImageModal onClose={closeImageModal} />}


    </div>
  );
}

export default App;

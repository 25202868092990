import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, onMessage  } from "firebase/messaging";

// Your web app's Firebase configuration (replace with your own)
const firebaseConfig = {
  apiKey: "AIzaSyCGwT3K78IbKnUy12tTgaVNGnYzx49-zW8",
  authDomain: "chaind.firebaseapp.com",
  projectId: "chaind",
  storageBucket: "chaind.appspot.com",
  messagingSenderId: "183288615696",
  appId: "1:183288615696:web:59d2272c212e04ec198b95",
  measurementId: "G-945Q55G4PY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const db = getFirestore(app);
let messaging;
try {
    messaging = getMessaging(app);
} catch (err) {
    console.error('Failed to initialize Firebase Messaging', err);
}


export { auth, db, messaging};

import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import './Feed.css';
import { db } from './firebase'; // Adjust this import according to your project structure
import { collection, getDocs } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCGwT3K78IbKnUy12tTgaVNGnYzx49-zW8",
    authDomain: "chaind.firebaseapp.com",
    projectId: "chaind",
    storageBucket: "chaind.appspot.com",
    messagingSenderId: "183288615696",
    appId: "1:183288615696:web:59d2272c212e04ec198b95",
    measurementId: "G-945Q55G4PY"
  };



  

const testImages = [
    "https://illustration-generated.s3.us-west-1.amazonaws.com/1704058379_857.png",
    "https://illustration-generated.s3.us-west-1.amazonaws.com/1704211059_814.png",
    "https://illustration-generated.s3.us-west-1.amazonaws.com/1704065204_285.png",
    // Add more image URLs as needed
];



const styles = {
    slideContainer: {
        height: '100vh',
    },
    slide: (imageUrl) => ({
        padding: 15,
        boxSizing: 'border-box',
        height: '100vh',
        color: '#fff',
        background: `url(${imageUrl}) no-repeat center center`,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position:'relative'
    }),
};

function DemoAxis() {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "FEED"));
                const urls = querySnapshot.docs.map(doc => doc.data().url);
                setImages(urls);
            } catch (error) {
                console.error("Error fetching images from Firestore: ", error);
            }
        };

        fetchImages();
    }, []);


    return (
        <SwipeableViews containerStyle={styles.slideContainer} axis="y" resistance enableMouseEvents>
            {images.map((src, index) => (
                <div key={index} style={styles.slide(src)}>
                    <div className="text">Title of image {index + 1}</div>
                    <div className="text-small">Description #cute #sexy</div>
                    <div className="heart"><img src="heart.png"  width="32px" alt="Heart"/><span>18</span></div>
                    <div className="share"><img src="share.png"  width="32px" alt="Share"/><span>18</span></div>
                </div>
            ))}
        </SwipeableViews>
    );
}

export default DemoAxis;


import React from 'react';
import {useState} from 'react';
import './Chaturbate.css';
import { Input } from '@mui/joy';
import { Textarea } from '@mui/joy';

function Chaturbate() {
const [username, setUsername] = useState("");
const [stage, setStage] = useState(1);
 return(
    <>
    {stage === 1 ? 
    <div className="chaturbateLanding">
        <div className="chaturbateLandingTitle">Are you a <span style={{color:"#f0bd22"}}> chaturbate model</span>?</div>
        <div className="chaturbateLandingQuestion">Want to earn more than just the tokens on Chaturbate?</div>
        <div className="chaturbateLandingQuestion">Want to join a community of Chaturbate streamers that make affiliate income?</div>
        <div className="chaturbateLandingSlogan">Join the fastest growing industry in the world. Work with <span style={{color:"#ff00d0"}}>AI girls</span> to earn cash.</div>
        <div className="chaturbateLandingSlogan">You get 100% of the profits, we grow the network.</div>
        <div className="chaturbateLandingButton" onClick={() => setStage(2)}>Get started now!</div>
    </div>

    :
    <div className="mainApp">
        <img className="chaturbateLogo" height="50px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Chaturbate_logo.svg/2560px-Chaturbate_logo.svg.png"></img>
        <div className="chaturbateLeft">
        <iframe width="100%" height="500" src="https://www.youtube.com/embed/zZNN3VQKxpI" title="the 2 best pieces of advice you’ll hear, ever." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div className="chaturbateRight">
            <div className="chaturbateTitle">Become a Lollipop affiliate on Chaturbate</div>
            <div className="chaturbateStep">Step 1</div>
            <div className="stepDescription">Enter your <a href="https://chaturbate.com/" target="_blank">Chaturbate</a> username to get started</div>
            <br/>
            <Input placeholder="For example: lollipopagency" type="text" value={username} onChange={(e) => setUsername(e.target.value)}></Input>

            <div className="chaturbateStep">Step 2</div>
            <div className="stepDescription">Create your affiliate link with your chaturbate username. Use "ref" and your chaturbate username, when asked.</div>
            <div style={{marginTop:"20px"}}><a className="chaturbateButton" href="https://affiliate.lollipop.chat" target="_blank">Become an affiliate</a></div>

            <div className="chaturbateStep">Step 3</div>
            <div className="stepDescription">Paste this into your About Me section in your Bio.</div>
            <Textarea sx={{width:"100%"}} value={`<a href="https://lollipop.chat/ref=${username}"><img src="https://i.ibb.co/wQdZpqK/Frame-41-3.png" /></a>`}/>
            {!username ? <div style={{color:"red", fontSize:"13px"}}>Please enter your Chaturbate username first!</div> : null}

            <div className="chaturbateStep">Step 4</div>
            <div className="stepDescription">Enable the <a href="https://chaturbate.com/apps/app_details/lollichat/?version=&slot=0" target="_blank">Lollipop bot</a> for your broadcast.</div>

            <div className="chaturbateStep">Step 5</div>
            <div className="stepDescription">You're all set! Start your broadcast and earn cash!</div>
            <div>If you need a chat, drop a message at <a href="mailto:lollipopagencyai@gmail.com">lollipopagencyai@gmail.com</a></div>
        </div>
    </div>
    }
    </>
 )
}

export default Chaturbate;

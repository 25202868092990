
import React from 'react';

function Ads() {
 return(
  <>
  <img src="ad1.png"></img>
  </>
 )
}

export default Ads;

import React, { useState, useEffect, useRef } from 'react';
import useSpeechToText from 'react-hook-speech-to-text';
import axios from 'axios';
import './Calling.css';
import Swal from 'sweetalert2';

export default function App() {
  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    crossBrowser: true,
    googleApiKey: 'AIzaSyCQBj-ByBt1KwiGFEkHtmpKPRD-uqfKfw4', // Replace with your Google Cloud API key
    useLegacyResults: false,
  });

  const [audioUrl, setAudioUrl] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [chatHistory, setChatHistory] = useState([]);
  const annaImageRef = useRef(null);
  const audioRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyzerRef = useRef(null);
  const sourceRef = useRef(null);
  const [password, setPassword] = useState('');
  const [waitingformessage, setWaitingformessage] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false); // Flag to prevent rapid calls
  const [lastTranscript, setLastTranscript] = useState(''); // Store the last processed transcript

  useEffect(() => {
    const savedPassword = localStorage.getItem('password');
    if (savedPassword) {
      setPassword(savedPassword);
    } else {
      let pw = prompt("Enter the passcode to continue");
      localStorage.setItem('password', pw);
      setPassword(pw);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (audioUrl && audioRef.current) {
      const audio = audioRef.current;

      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        analyzerRef.current = audioContextRef.current.createAnalyser();
        analyzerRef.current.fftSize = 256;
      }

      const audioContext = audioContextRef.current;
      const analyzer = analyzerRef.current;

      if (!sourceRef.current) {
        sourceRef.current = audioContext.createMediaElementSource(audio);
        sourceRef.current.connect(analyzer);
        analyzer.connect(audioContext.destination);
      }

      const bufferLength = analyzer.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const scaleImage = () => {
        analyzer.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((sum, value) => sum + value, 0) / bufferLength;
        const scale = 1 + (average / 1024);
        if (annaImageRef.current) {
          annaImageRef.current.style.transform = `scale(${scale})`;
        }
        requestAnimationFrame(scaleImage);
      };

      audio.play().then(() => {
        scaleImage();
      }).catch(error => {
        console.log('Playback failed due to browser autoplay policy.', error);
      });
    }
  }, [audioUrl]);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const swal = () => {
    Swal.fire({
      position: "bottom-end",
      title: "That didn't work. Try again please.",
      showConfirmButton: false,
      timer: 1500
    });
  };

  useEffect(() => {
    if (results.length > 0) {
      const lastResult = results[results.length - 1];
      const transcript = lastResult.transcript.trim();

      if (!transcript || isProcessing || transcript === lastTranscript) return; // Skip empty, ongoing, or repeated transcripts

      setLastTranscript(transcript);

      const newChatHistory = [...chatHistory, { role: 'user', content: transcript }];
      if (newChatHistory.length > 10) {
        newChatHistory.shift();
      }

      const processSpeech = async () => {
        try {
          setIsProcessing(true);
          setWaitingformessage(true);
          const response = await axios.post('https://lollipopchat-ca692e070ff0.herokuapp.com/api/process', { text: transcript, history: newChatHistory, password: password });

          if (response.status === 200) {
            const { audioData, history } = response.data;
            const audioBlob = new Blob([Uint8Array.from(atob(audioData), c => c.charCodeAt(0))], { type: 'audio/mpeg' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
            setChatHistory(history);
            setWaitingformessage(false);
          } else {
            console.error('Error: Received non-200 response');
            swal();
          }
        } catch (error) {
          console.error('Error processing speech:', error);
          swal();
        } finally {
          setIsProcessing(false);
        }
      };

      processSpeech();
    }
  }, [results]);

  return (
    <div className="Appcalling">
      {error && <p>Web Speech API is not available in this browser 🤷‍</p>}
      <img
        ref={annaImageRef}
        className={`annaimage ${waitingformessage ? 'border-animation' : ''}`}
        src="./anna9.png"
        alt="Anna"
      />
      <div className="calling">Calling</div>
      <div className="annamaria">Anna Maria</div>
      <div className="timer">{formatTime(seconds)}</div>
      <div className="gradient-div"></div>
      <button
        className="speak"
        onClick={isRecording ? stopSpeechToText : startSpeechToText}
      >
        {isRecording ? 'Click to stop speaking' : 'Click to start speaking'}
      </button>

      {audioUrl && (
        <audio ref={audioRef} className="audioplayer" src={audioUrl} controls autoPlay />
      )}
      <ul>
        {results.map((result) => (
          <li key={result.timestamp}>{result.transcript}</li>
        ))}
        {interimResult && <li>{interimResult}</li>}
      </ul>
    </div>
  );
}

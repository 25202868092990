import React, { useState } from 'react';
import axios from 'axios';
import './Workspace.css';

function App() {
  const [prompt, setPrompt] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('https://us-central1-chaind.cloudfunctions.net/api/generate-image', { keywords: prompt });
      setImages([...images, response.data.content]);
    } catch (error) {
      console.error('Error fetching image:', error);
    }

    setLoading(false);
  };

  return (
    <div className="Workspace">
      <form onSubmit={handleSubmit}>
        <input
          className="workspaceinput" 
          type="text" 
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter prompt"
        />
        <button className="workspacebutton" type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Get Image'}
        </button>
      </form>
      <div>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`From prompt: ${prompt}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
